import React from 'react'
import PropTypes from 'prop-types'
import {graphql} from 'gatsby'
import {HTMLContent } from '../components/Content'
import BasicLayout from "../components/layout/BasicLayout";
import Button from "../components/Button";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export const ServiceTemplate = ({
                                     content,
                                     title,
                                     date
                                 }) => {
    return (
        <section>
            <article>
                <header>
                    <h1 className="copy-header copy-header--xl">
                        {title}
                    </h1>
                </header>

                <HTMLContent content={content} className="content" />

                <section className={"p-blog--end l-component"}>
                    <Button type={"text"} link="/services">
                        <FontAwesomeIcon icon={faArrowLeft}  size="s" /> <span>Back</span>
                    </Button>
                </section>
            </article>
        </section>
    )
}

ServiceTemplate.propTypes = {
    content: PropTypes.node.isRequired,
    title: PropTypes.string,
    date: PropTypes.string
}

const ServicePost = ({ data }) => {
    const { markdownRemark: post } = data

    return (
        <BasicLayout pageName={"service"} title={post.frontmatter.title}>
            <ServiceTemplate
                content={post.html}
                title={post.frontmatter.title}
                date={post.frontmatter.date}
            />
        </BasicLayout>
    )
}

ServicePost.propTypes = {
    data: PropTypes.shape({
        markdownRemark: PropTypes.object,
    }),
}

export default ServicePost

export const pageQuery = graphql`
  query ServicePostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
      }
    }
  }
`
